import { checkModalDataHasChanged } from '../../utilities/modalHelper';
import {
    useCallback,
    useRef,
    useState,
    useEffect,
    useContext,
    useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setNoPrescriptionsPreference } from 'services/clientServices/slice';

import { useAuth0 } from '@auth0/auth0-react';

import BackButton from 'components/BackButton';
import Button from 'components/Button';
import {
    completeMedicareCurrentStep,
    previousMedicareStep,
    resetStepperState,
    skipMedicareCurrentStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import CreateAccountBanner from 'components/CreateAccountBanner';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import TextAsLink from 'components/TextAsLink';
import { setDoctorModal } from 'components/YourDoctorModal/slice';
import { setMedicaidModal } from 'components/YourMedicaidModal/slice';
import { setProfilePrescriptionsContentModal } from 'components/YourProfilePrescriptionsModal/slice';
import useFetch from 'components/hooks/useFetch';

import prescriptionModalContext from 'contexts/prescriptionModal';

import { QuickProfileSkipButton } from 'pages/QuickProfileSkipButton';

import dataLayer from 'utilities/dataLayer';
import { CONSUMER_BASE_URL } from 'utilities/env';
import { QUICK_PROFILE_ROUTE } from 'utilities/routes';
import {
    DRUG_NAMES_KEY,
    HEALTH_CONDITION_NO_HEALTH_CONDITION,
    PRESCRIPTION_NO_PRESCRIPTIONS,
    PRESCRIPTION_SEARCH_TERM_KEY
} from 'utilities/storageKeys';

import image from './image.svg';

import {
    buttonLabel,
    buttonLabel1,
    buttonLabel2,
    headerText,
    headerTitle,
    headerTitle1,
    plansButtonLabel,
    plansButtonLabel1
} from './constants';
import {
    setDrugNames,
    setHasNoPrescriptions,
    setIsApiFailed,
    setIsEdit,
    setSelectedDrugId,
    setSelectedPlan
} from './slice';

import styles from './styles.module.scss';

import DrugDetails from 'temp/components/DrugDetails';
import PrescriptionSearch from 'temp/components/PrescriptionSearch';
import { setIsModalEdited } from 'temp/pages/Plans/slice';
import {
    addPrescription,
    deletePrescription,
    getPrescriptions
} from 'temp/utilities/apiSession';
import { updatePrescription } from 'temp/utilities/apiSession/prescriptions';
import { get, set } from 'temp/utilities/storage';

const YourPrescriptionModal = ({
    isOpen,
    onChange = () => null,
    isFromProfile = false,
    PER_PAGE,
    editDrug = null,
    addDrug = false,
    setAddDrug = () => {}
}) => {
    const history = useHistory();
    const { user, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const initialDrugNames = useSelector(
        state => state.prescriptionDetails.drugNames
    );
    const isEdit = useSelector(state => state.prescriptionDetails.isEdit);

    const { setPrescriptionModal } = useContext(prescriptionModalContext);
    const [selectedPrescriptions, setSelectedPrescriptions] = useState([]);
    const [selectedPrescription, setSelectedPrescription] = useState(null);
    const [isPrescriptionsChanged, setIsPrescriptionsChanged] = useState(false);
    const inputRef = useRef(null);
    const [prescription, setPrescription] = useState(null);
    const [savedPrescription, setSavedPrescription] = useState({});
    const [showLink, setShowLink] = useState(true);
    const [addNew, setAddNew] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [footerButtonDisabled, setFooterButtonDisabled] = useState(false);
    const [drug, setDrug] = useState(null);
    const [isFromPF, setIsFromPF] = useState(false);
    const [isBackButtonVisible, setIsBackButtonVisible] = useState(true);
    const [drugName, setDrugName] = useState(
        get(PRESCRIPTION_SEARCH_TERM_KEY, '')
    );
    const [detailsModalDisabled, setDetailsModalDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);
    const { syncId, consumerId } = useSelector(
        state => state.userProfile.userProfile
    );
    const selectedPlan = useSelector(
        state => state.prescriptionDetails.selectedPlan
    );
    const hasNoPrescriptions = useSelector(
        state => state.prescriptionDetails.hasNoPrescriptions
    );
    const { isAuthenticated } = useAuth0();
    const { Post: submitPreference } = useFetch(
        `${CONSUMER_BASE_URL}/Preference/Preference`
    );

    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    useEffect(() => {
        if (isOpen) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [isOpen]);

    const onClose = (isSkip = false) => {
        if (!isSkip) {
            dispatch(resetStepperState());
        }
        setAddDrug(false);
        setPrescriptionModal(false);
        dispatch(setSelectedPlan(null));
        clearAll();

        const drugNames = selectedPrescriptions.map(item => {
            const { drugName, labelName } = item?.dosage || {};

            return {
                drugName,
                labelName
            };
        });

        if (checkModalDataHasChanged(initialDrugNames, drugNames)) {
            dispatch(setDrugNames(drugNames));
        }

        set(DRUG_NAMES_KEY, drugNames);
        if (isPrescriptionsChanged) {
            dispatch(setIsModalEdited(true));
        }
    };
    const showAddList = () => {
        inputRef?.current?.showAddList && inputRef.current.showAddList();
    };

    const handleAddPrescription = async () => {
        setLoading(true);
        const authToken = await getAuthToken();
        const prescriptions = await addPrescription({
            ...prescription,
            syncId,
            authToken
        });
        setPrescriptions(prescriptions);
        if (isFromProfile) {
            const filterPrescription = prescriptions?.filter(
                data => data?.dosage?.dosageID === prescription?.dosageID
            );

            setSavedPrescription({
                ...filterPrescription[0]?.dosage,
                id: filterPrescription[0]?.dosage?.dosageRecordID,
                dosages: [filterPrescription[0]?.dosageDetails]
            });
        }
        toggleHasNoPrescriptions(false);
        setShowSearch(false);
        handleRefresh();
    };

    const handleUpdatePrescription = async () => {
        setLoading(true);
        const authToken = await getAuthToken();
        const { id: dosageRecordID } = selectedPrescription;
        const prescriptions = await updatePrescription({
            ...prescription,
            dosageRecordID,
            authToken
        });
        setPrescriptions(prescriptions);
        setShowSearch(false);
        handleRefresh();
    };

    const clearAll = state => {
        setDrug(null);
        setSelectedPrescription(null);
        setPrescription(null);
        if (!state) {
            handleClear();
            setDrugName('');
        }
        setLoading(false);
        dispatch(setIsEdit(false));
        dispatch(setSelectedDrugId());
    };

    const handleRefresh = () => {
        clearAll();
        showAddList();
    };

    const footerButtonClicked = async e => {
        if (selectedPrescription) {
            window.location.href.includes('quick-profile') &&
                document.getElementById('subHeading')?.scrollIntoView();
            setDrugName('');
            setFooterButtonDisabled(true);
            if (prescription && !isEdit) {
                await handleAddPrescription();
                if (isFromProfile) {
                    setIsBackButtonVisible(true);
                    setIsFromPF(false);
                }
                setIsBackButtonVisible(true);
            } else if (prescription && isEdit) {
                await handleUpdatePrescription();
                if (isFromProfile) {
                    setIsBackButtonVisible(true);
                    setIsFromPF(false);
                }
                setIsBackButtonVisible(true);
            } else {
                setDrug(selectedPrescription);
            }
            localStorage.setItem(
                'selectedPrescription',
                JSON.stringify(selectedPrescription)
            );
            setFooterButtonDisabled(false);
        } else if (
            (editDrug && !isFromPF && isFromProfile) ||
            (!editDrug && !isFromPF && addDrug && isFromProfile)
        ) {
            setAddDrug(false);
            setPrescriptionModal(false);
            dispatch(setProfilePrescriptionsContentModal(true));
        } else if (window.location.href.includes('quick-profile')) {
            history.push({
                pathname: QUICK_PROFILE_ROUTE,
                search: `#coverage`
            });
        } else if (!showSearch && !isGuided) {
            setShowSearch(true);
        } else {
            onClose(true);
            onChange(true);
            if (isGuided) {
                selectedPrescriptions.length > 0
                    ? dispatch(completeMedicareCurrentStep())
                    : dispatch(skipMedicareCurrentStep());
                dispatch(setMedicaidModal(true));
            }
            if (isPrescriptionsChanged) {
                dispatch(setIsModalEdited(true));
            }
        }
    };

    useEffect(() => {
        if (selectedPlan) {
            const { planDrugCoverage } = selectedPlan;
            const planDrugs = planDrugCoverage.reduce((a, item) => {
                a[item.ndc] = item;
                return a;
            }, {});
            const prescriptions = selectedPrescriptions.map(prescription => {
                let drug = planDrugs[prescription.dosage.ndc];
                prescription['inOutNetwork'] = Boolean(drug.tierNumber);
                return prescription;
            });
            setPrescriptions(prescriptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlan]);

    const setPrescriptions = useCallback(prescriptions => {
        set('selectedPrescriptions', prescriptions);
        setSelectedPrescriptions(prescriptions);
        setIsPrescriptionsChanged(true);
    }, []);

    const handleBackButton = () => {
        if (isFromProfile) {
            setIsFromPF(true);
            setIsBackButtonVisible(false);
            handleSelectedPrescription(savedPrescription, true);
            setShowSearch(false);
            dispatch(setIsEdit(true));
        } else if (isEdit) {
            clearAll();
        } else if (drug) {
            clearAll(true);
        } else {
            dispatch(setDoctorModal(true));
            setPrescriptionModal(false);
            dispatch(previousMedicareStep());
        }
    };

    const handleSkipButton = () => {
        onClose(true);
        dispatch(setDoctorModal(false));
        dispatch(setMedicaidModal(true));
        selectedPrescriptions.length > 0
            ? dispatch(completeMedicareCurrentStep())
            : dispatch(skipMedicareCurrentStep());
    };

    const isPrescriptionRef = useRef(false);
    const firstRef = useRef(true);

    useEffect(() => {
        if (firstRef.current) {
            firstRef.current = false;
            selectedPrescription && setFooterButtonDisabled(false);
        }
    }, []);

    useEffect(() => {
        if (!isPrescriptionRef.current) {
            (async () => {
                isPrescriptionRef.current = true;
                const authToken = await getAuthToken();
                const prescriptions = await getPrescriptions(authToken);
                if (!prescriptions) {
                    dispatch(setIsApiFailed(true));
                } else {
                    dispatch(setIsApiFailed(false));
                }
                setShowSearch(!prescriptions?.length);
                setPrescriptions(prescriptions);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, setPrescriptions]);

    useEffect(() => {
        setShowSearch(!selectedPrescriptions?.length);
    }, [selectedPrescriptions, isOpen]);

    const removePrescription = async ({ id }) => {
        const authToken = await getAuthToken();
        await deletePrescription({ id, syncId, authToken });

        const prescriptions = await getPrescriptions(authToken);
        if (prescriptions === null) {
            dispatch(setIsApiFailed(true));
        } else {
            dispatch(setIsApiFailed(false));
        }

        setPrescriptions(prescriptions);
        handleRefresh();
        hasNoPrescriptions && setShowSearch(false);
        if (isFromPF) {
            setPrescriptionModal(false);
            dispatch(setProfilePrescriptionsContentModal(true));
        }
        setIsBackButtonVisible(true);
    };

    const searchTermChanged = searchTerm => {
        set(PRESCRIPTION_SEARCH_TERM_KEY, searchTerm);
        setDrugName(searchTerm);
    };

    const onBack = async () => {
        const details = localStorage.getItem('selectedPrescription');
        if (details) {
            setDrug(null);
            setPrescription(null);

            setSelectedPrescription(JSON.parse(details));

            const search = get(PRESCRIPTION_SEARCH_TERM_KEY);
            setDrugName(search);
            localStorage.removeItem('selectedPrescription');
        } else {
            setAddDrug(false);
            setPrescriptionModal(false);
            dispatch(setSelectedPlan(null));
            clearAll();
            history.push({
                pathname: QUICK_PROFILE_ROUTE,
                search: `#doctors`
            });
        }
    };

    const handleClear = e => {
        searchTermChanged('');
    };

    const getFooterButtonDisabledStatus = () => {
        if (showSearch) {
            return !selectedPrescription;
        } else {
            return !showLink && (!drug || detailsModalDisabled);
        }
    };

    const selectedDrugId = useSelector(
        state => state.prescriptionDetails.selectedDrugId
    );
    const getFooterButtonDisabled = () => {
        if (showSearch) {
            return !selectedPrescription && !selectedDrugId;
        } else {
            return !showLink && (!drug || detailsModalDisabled);
        }
    };

    const handleSelectedPrescription = (data, edit) => {
        setSavedPrescription(data);
        setIsBackButtonVisible(false);
        setSelectedPrescription(data);
        if (edit) setDrug(data);
        dispatch(setSelectedDrugId(data?.drugID));
    };

    const getHeaderTitle = useMemo(
        () => (isEdit ? headerTitle : headerTitle1),
        [isEdit]
    );

    const getFooterText = useMemo(() => {
        if (!showSearch && editDrug && isFromPF) return buttonLabel2;
        if (!showSearch && editDrug && !isFromPF) return plansButtonLabel1;
        if ((!showSearch || addDrug) && !editDrug && isFromPF)
            return plansButtonLabel1;
        if (!showSearch && addDrug && !editDrug && !isFromPF)
            return plansButtonLabel1;
        if (showSearch && !editDrug && isFromPF) return plansButtonLabel;

        if (!showSearch && isEdit) return buttonLabel1;
        if (drug || (!showSearch && !isGuided)) return buttonLabel;
        if (isGuided && !showSearch) return plansButtonLabel;
        if (isGuided || editDrug) return plansButtonLabel1;
        return plansButtonLabel;
    }, [showSearch, isEdit, drug, isGuided, isFromPF]);

    const getFooterButtonText = useMemo(() => {
        if (!addNew && hasNoPrescriptions) return plansButtonLabel;
        if (drug || showSearch || isEdit) return plansButtonLabel1;

        return plansButtonLabel;
    }, [showSearch, isEdit, drug, addNew]);

    const onSelectHasNoPrescriptions = () => {
        setShowSearch(false);
        toggleHasNoPrescriptions(true);
        setAddNew(false);
        setFooterButtonDisabled(false);
    };

    const toggleHasNoPrescriptions = value => {
        set(PRESCRIPTION_NO_PRESCRIPTIONS, value);
        dispatch(setHasNoPrescriptions(value));
        if (isAuthenticated) {
            submitPreference({
                consumerId,
                preferences: {
                    hasNoHealthConditions: get(
                        HEALTH_CONDITION_NO_HEALTH_CONDITION
                    ),
                    hasNoPrescriptions: !!value
                }
            });
            dispatch(setNoPrescriptionsPreference(value));
        }
    };

    useEffect(() => {
        if (isFromProfile) {
            if (editDrug) {
                handleSelectedPrescription(editDrug, true);
                setShowSearch(!editDrug);
                dispatch(setIsEdit(true));
                setIsFromPF(true);
                setIsBackButtonVisible(false);
            } else {
                setShowSearch(true);
                setAddNew(true);
                setIsBackButtonVisible(true);
            }
        }
    }, [editDrug]);

    useEffect(() => {
        if (addDrug && isFromProfile) {
            setShowSearch(true);
            setAddNew(true);
            setIsFromPF(true);
            setIsBackButtonVisible(false);
        }
    }, [addDrug]);

    const skipButtonClicked = () => {
        history.push({
            pathname: QUICK_PROFILE_ROUTE,
            search: `#coverage`
        });
    };

    return (
        <>
            {!window.location.href.includes('quick-profile') ? (
                <div className={styles.modalWithHeaderFooter}>
                    <Modal className={styles.modal} isOpen={isOpen}>
                        <ModalWindow
                            backButtonClicked={handleBackButton}
                            closeIconClassName={styles.closeIconClassName}
                            contentClassName={styles.contentClassName}
                            drug={drug}
                            footerButtonClassName={styles.footerButtonClassName}
                            footerButtonClicked={footerButtonClicked}
                            footerButtonDisabled={
                                footerButtonDisabled ||
                                getFooterButtonDisabledStatus()
                            }
                            footerClassName={styles.footerClassName}
                            footerLabel={getFooterText}
                            headerClassName={styles.headerClassName}
                            headerIcon={image}
                            headerText={headerText}
                            headerTitle={getHeaderTitle}
                            isBack={isGuided && isBackButtonVisible}
                            isSkip={isGuided}
                            onCancel={() => {
                                setDrug(null);
                                setDrugName('');
                            }}
                            onClose={() => onClose()}
                            plansButtonLabel={plansButtonLabel}
                            selectedPrescriptions={selectedPrescriptions}
                            skipButtonClassName={styles.skipButtonClassName}
                            skipButtonClicked={handleSkipButton}
                        >
                            <div
                                className={styles.content}
                                data-testid="your-prescription-modal-content"
                            >
                                {drug?.drugID ? (
                                    <DrugDetails
                                        drug={drug}
                                        isEdit={isEdit}
                                        onClose={() => setDrug(null)}
                                        removePrescription={removePrescription}
                                        setPrescription={setPrescription}
                                        validate={setDetailsModalDisabled}
                                    />
                                ) : (
                                    <>
                                        {(hasNoPrescriptions
                                            ? addNew
                                            : true) && (
                                            <PrescriptionSearch
                                                defaultValue={drugName}
                                                deletePrescription={
                                                    removePrescription
                                                }
                                                handleSelectedPrescription={
                                                    handleSelectedPrescription
                                                }
                                                inputRef={inputRef}
                                                onChange={searchTermChanged}
                                                onClear={handleClear}
                                                prescriptions={
                                                    selectedPrescriptions
                                                }
                                                setDrug={setDrug}
                                                setShowLink={setShowLink}
                                                showLink={showLink}
                                                showSearch={showSearch}
                                                setShowSearch={setShowSearch}
                                                isFromProfile={isFromProfile}
                                                isBackButtonVisible={
                                                    setIsBackButtonVisible
                                                }
                                                selectedPrescriptions={
                                                    selectedPrescriptions
                                                }
                                                onSelectHasNoPrescriptions={
                                                    onSelectHasNoPrescriptions
                                                }
                                            />
                                        )}
                                    </>
                                )}
                                {selectedPrescriptions.length === 0 &&
                                hasNoPrescriptions &&
                                !addNew ? (
                                    <div>
                                        <div
                                            className={
                                                styles.noPrescriptionInfoContainer
                                            }
                                        >
                                            <Text
                                                className={
                                                    styles.noPrescriptionInfoText
                                                }
                                                text={`I currently don’t take any prescriptions.`}
                                                id="conditionText"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.noPrescriptionAddbtnContainer
                                            }
                                        >
                                            <TextAsLink
                                                onClick={() => {
                                                    setShowSearch(true);
                                                    setAddNew(true);
                                                }}
                                                className={
                                                    styles.noPrescriptionAddbtn
                                                }
                                                text="Add Prescription +"
                                                id="addAnotherPrescriptionLink"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {loading && <Spinner />}
                            </div>

                            {isGuided && false && <CreateAccountBanner />}
                        </ModalWindow>
                    </Modal>
                </div>
            ) : (
                <>
                    <div
                        className={styles.quickProfileContent}
                        data-testid="your-prescription-modal-content"
                    >
                        {drug?.drugID ? (
                            <DrugDetails
                                drug={drug}
                                isEdit={isEdit}
                                onClose={() => setDrug(null)}
                                removePrescription={removePrescription}
                                setPrescription={setPrescription}
                                validate={setDetailsModalDisabled}
                            />
                        ) : (
                            <>
                                {(hasNoPrescriptions ? addNew : true) && (
                                    <PrescriptionSearch
                                        defaultValue={drugName}
                                        deletePrescription={removePrescription}
                                        PER_PAGE={PER_PAGE}
                                        handleSelectedPrescription={
                                            handleSelectedPrescription
                                        }
                                        inputRef={inputRef}
                                        onChange={searchTermChanged}
                                        onClear={handleClear}
                                        prescriptions={selectedPrescriptions}
                                        setDrug={setDrug}
                                        setShowLink={setShowLink}
                                        showLink={showLink}
                                        showSearch={showSearch}
                                        setShowSearch={setShowSearch}
                                        isFromProfile={isFromProfile}
                                        isBackButtonVisible={
                                            setIsBackButtonVisible
                                        }
                                        selectedPrescriptions={
                                            selectedPrescriptions
                                        }
                                        onSelectHasNoPrescriptions={
                                            onSelectHasNoPrescriptions
                                        }
                                    />
                                )}
                            </>
                        )}
                        {selectedPrescriptions.length === 0 &&
                        hasNoPrescriptions &&
                        !addNew ? (
                            <div>
                                <div
                                    className={
                                        styles.noPrescriptionInfoContainer
                                    }
                                >
                                    <Text
                                        className={
                                            styles.noPrescriptionInfoText
                                        }
                                        text={`I currently don’t take any prescriptions.`}
                                        id="conditionText"
                                    />
                                </div>
                                <div
                                    className={
                                        styles.noPrescriptionAddbtnContainer
                                    }
                                >
                                    <TextAsLink
                                        onClick={() => {
                                            setShowSearch(true);
                                            setAddNew(true);
                                        }}
                                        className={styles.noPrescriptionAddbtn}
                                        text="Add Prescription +"
                                        id="addAnotherPrescriptionLink"
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {loading && <Spinner />}
                    </div>

                    <div className={styles.divider} />

                    <div className={styles.submitButtonContainer}>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <>
                                <BackButton
                                    className={styles.backButton}
                                    disabled={false}
                                    onClick={onBack}
                                    label={'Back'}
                                />
                                <div className={styles.skipWithSubmit}>
                                    <QuickProfileSkipButton
                                        skipButtonClicked={skipButtonClicked}
                                    />
                                    <Button
                                        className={styles.submitButton}
                                        disabled={
                                            (footerButtonDisabled ||
                                                getFooterButtonDisabled()) &&
                                            !(
                                                selectedPrescriptions.length ===
                                                    0 &&
                                                hasNoPrescriptions &&
                                                !addNew
                                            )
                                        }
                                        onClick={footerButtonClicked}
                                        label={getFooterButtonText}
                                        type="submit"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
export default YourPrescriptionModal;
