import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'swiper';
import 'swiper/modules/pagination/pagination.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

import { Avatar } from '@mui/material';

import ButtonLink from 'components/ButtonLink';
import ConsumerGuidedExperienceModal from 'components/ConsumerGuidedExperienceModal';
import { setGuidedModal } from 'components/ConsumerGuidedExperienceModal/slice';
import Heading3 from 'components/Heading3';
import Heading5 from 'components/Heading5';
import Icon from 'components/Icon';
import InfoCard from 'components/InfoCard';
import LinkText from 'components/LinkText';
import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';
import { setSelectedPlanType } from 'components/PlanTypeModal/slice';
import ProfilePicture from 'components/ProfilePicture';
import WalletHealthCard from 'components/WalletHealthCard';
import YourHealthPlanModal from 'components/YourHealthPlanModal';
import { setHealthPlanContentModal } from 'components/YourHealthPlanModal/slice';
import YourLifePlanModal from 'components/YourLifePlanModal';
import { setLifePlanContentModal } from 'components/YourLifePlanModal/slice';

import { getPlans } from 'utilities/apiSession/plans';
import { FINAL_EXPENSE_AGENT, REQUEST_CALL_BACK } from 'utilities/routes';
import { SELECTED_PLAN_TYPE } from 'utilities/storageKeys';

import avatarIcon from './icons/AvatarAgentPic.svg';
import arrowIcon from './icons/arrow.svg';
import envelopeIcon from './icons/envelope.svg';
import healthInsuranceIcon from './icons/health_insurance.svg';
import lifeInsuranceIcon from './icons/life_insurance.svg';
import notAvailableIcon from './icons/notAvailable.svg';
import phoneIcon from './icons/phone.svg';
import verifiedIcon from './icons/verified.svg';

import InsuranceAgentDetailsModal from './InsuranceAgentDetailsModal';
import {
    currentHeathHeading,
    currentLifeHeading,
    heathInsuranceHeading,
    lifeInsuranceHeading
} from './constant';
import './slider.scss';

import styles from './styles.module.scss';

import { set } from 'temp/utilities/storage';

const defaultSwiperProps = {
    swiper: 'true',
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    direction: 'horizontal',
    pagination: {
        enabled: true,
        type: 'bullets',
        el: '.custom-pagination-div',
        clickable: true,
        renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>';
        }
    },
    modules: [Pagination]
};

const defaultSwiperProps1 = {
    swiper: 'true',
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    direction: 'horizontal',
    pagination: {
        enabled: true,
        type: 'bullets',
        el: '.custom-pagination-div1',
        clickable: true,
        renderBullet: (index, className) => {
            return '<span class="' + className + '"></span>';
        }
    },
    modules: [Pagination]
};

export const LifeInsuranceContent = ({
    lifePlans,
    openModal,
    onClickViewAgent,
    agentDetails
}) => {
    const phoneNumber = agentDetails?.virtualNumber?.substring(2);
    const dispatch = useDispatch();
    const openGuidedLifeInsuranceModal = () => {
        set(SELECTED_PLAN_TYPE, finalExpensePlanType);
        dispatch(setSelectedPlanType(finalExpensePlanType));
        dispatch(setGuidedModal(true));
    };
    return (
        <div className={styles.lifeInsuranceContentContainer}>
            <div className={styles.cardTitlesContainer}>
                <Heading5
                    className={`${styles.cardBodyParaTitle} ${styles.cardBodySliderTitle}`}
                    text={
                        <>
                            {currentLifeHeading}{' '}
                            <span className={styles.plansCountBrackets}>
                                ({lifePlans.length})
                            </span>
                        </>
                    }
                />
                <Heading5
                    className={`${styles.cardBodyParaTitle} ${styles.cardBodyAgentTitle}`}
                    text={'Life Agent'}
                />
            </div>
            <div className={styles.cardBodyBox}>
                {Boolean(lifePlans?.length) && (
                    <div className={styles.sliderBox}>
                        <Swiper
                            className={styles.mySwiper}
                            {...defaultSwiperProps}
                        >
                            {lifePlans.map((item, index) => (
                                <SwiperSlide>
                                    <WalletHealthCard
                                        showAllByDefault={true}
                                        openHealthPlanModal={openModal}
                                        plansDetailsIndex={index}
                                        plansDetails={item}
                                        mouseOver={() => {}}
                                        mouseOverOut={() => {}}
                                        cardClassName={
                                            styles.healthCardClassName
                                        }
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div
                            className="custom-pagination-div"
                            id="customPagination"
                        />
                    </div>
                )}
                {!lifePlans?.length && (
                    <div
                        className={`${styles.sliderBox} ${styles.sliderBoxEmpty}`}
                    >
                        <div>
                            <Heading3
                                text={'Ready to find coverage in your area?'}
                                className={styles.readyToFindText}
                            />
                            <Heading3
                                text={
                                    'View a selection of Final Expense options available in your area.'
                                }
                                className={styles.viewSelectionText}
                            />
                        </div>
                        <div>
                            <ButtonLink
                                onClick={openGuidedLifeInsuranceModal}
                                className={styles.findPlanButton}
                                text={'Find Coverage'}
                            />
                        </div>
                    </div>
                )}
                <Heading5
                    className={`${styles.cardBodyParaTitle} ${styles.cardBodyAgentTitleMobile}`}
                    text={'Life Agent'}
                />
                {agentDetails?.agentFirstName && (
                    <div className={styles.agentBox}>
                        <div className={styles.agentContactIconsContainer}>
                            <a
                                className={styles.link}
                                href={`mailto:${agentDetails.email}`}
                                rel="noreferrer"
                            >
                                <Icon
                                    image={envelopeIcon}
                                    className={styles.agentContactIcon}
                                />
                            </a>
                            <a
                                className={styles.link}
                                href={`tel:${phoneNumber}`}
                                rel="noreferrer"
                            >
                                <Icon
                                    image={phoneIcon}
                                    className={styles.agentContactIcon}
                                />
                            </a>
                            <Icon
                                image={
                                    agentDetails.isPurlToggledOn
                                        ? verifiedIcon
                                        : notAvailableIcon
                                }
                                className={`${styles.agentContactIcon} ${styles.agentVerifiedIcon}`}
                            />
                        </div>
                        <div className={styles.agentDetailsPicNameContainer}>
                            {agentDetails.profilePicture ? (
                                <Avatar
                                    src={avatarIcon}
                                    alt="agent"
                                    className={styles.avatarIcon}
                                />
                            ) : (
                                <ProfilePicture
                                    hideCameraOverlay={true}
                                    className={styles.avatarIcon}
                                    firstName={agentDetails?.agentFirstName}
                                    lastName={agentDetails?.agentLastName}
                                />
                            )}
                            <div className={styles.agentNameLinkContainer}>
                                <Heading5
                                    className={styles.agentNameHeading}
                                    text={
                                        agentDetails.agentFirstName +
                                        ' ' +
                                        agentDetails.agentLastName
                                    }
                                />
                                <LinkText
                                    onClick={onClickViewAgent}
                                    url="#"
                                    text={
                                        <span
                                            className={`${styles.viewAgentLink} ${styles.viewAgentFullLink}`}
                                        >
                                            View Agent
                                            <Icon
                                                className={
                                                    styles.arrowClassName
                                                }
                                                image={arrowIcon}
                                            />
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!agentDetails?.agentFirstName && (
                    <div
                        className={`${styles.agentBox} ${styles.agentBoxEmptyState}`}
                    >
                        <div className={styles.connectAgentContainer}>
                            <Heading3
                                text="Get personalised guidance"
                                className={styles.getGuidanceTitle}
                            />

                            <Heading3
                                text={'Connect with an insurance agent'}
                                className={styles.connectAgentText}
                            />
                        </div>
                        <LinkText
                            url={FINAL_EXPENSE_AGENT}
                            text={
                                <span
                                    className={`${styles.viewAgentLink} ${styles.viewAgentLinkEmpty}`}
                                >
                                    Find an Agent
                                    <Icon
                                        className={styles.arrowClassName}
                                        image={arrowIcon}
                                    />
                                </span>
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export const HealthInsuranceContent = ({
    healthPlans,
    openModal,
    onClickViewAgent,
    agentDetails
}) => {
    const phoneNumber = agentDetails?.virtualNumber?.substring(2);
    const dispatch = useDispatch();
    const openGuidedHealthInsuranceModal = () => {
        set(SELECTED_PLAN_TYPE, medicarePlanType);
        dispatch(setSelectedPlanType(medicarePlanType));
        dispatch(setGuidedModal(true));
    };

    return (
        <div className={styles.healthInsuranceContentContainer}>
            <div className={styles.cardTitlesContainer}>
                <Heading5
                    className={`${styles.cardBodyParaTitle} ${styles.cardBodySliderTitle}`}
                    text={
                        <>
                            {currentHeathHeading}{' '}
                            <span className={styles.plansCountBrackets}>
                                ({healthPlans.length})
                            </span>
                        </>
                    }
                />
                <Heading5
                    className={`${styles.cardBodyParaTitle} ${styles.cardBodyAgentTitle}`}
                    text={'Health Agent'}
                />
            </div>
            <div className={styles.cardBodyBox}>
                {Boolean(healthPlans?.length) && (
                    <div className={styles.sliderBox}>
                        <Swiper
                            className={styles.mySwiper}
                            {...defaultSwiperProps1}
                        >
                            {healthPlans.map((item, index) => (
                                <SwiperSlide key={item.policyNumber}>
                                    <WalletHealthCard
                                        key={item.policyNumber}
                                        showAllByDefault={true}
                                        openHealthPlanModal={openModal}
                                        plansDetailsIndex={index}
                                        plansDetails={item}
                                        mouseOver={() => {}}
                                        mouseOverOut={() => {}}
                                        cardClassName={
                                            styles.healthCardClassName
                                        }
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div
                            className="custom-pagination-div1"
                            id="customPagination1"
                        />
                    </div>
                )}
                {!healthPlans?.length && (
                    <div
                        className={`${styles.sliderBox} ${styles.sliderBoxEmpty}`}
                    >
                        <div>
                            <Heading3
                                text={'Ready to find plans in your area?'}
                                className={styles.readyToFindText}
                            />
                            <Heading3
                                text={
                                    'View a selection of Medicare plans available in your area.'
                                }
                                className={styles.viewSelectionText}
                            />
                        </div>
                        <div>
                            <ButtonLink
                                onClick={openGuidedHealthInsuranceModal}
                                className={styles.findPlanButton}
                                text={'Find a Plan'}
                            />
                        </div>
                    </div>
                )}
                <Heading5
                    className={`${styles.cardBodyParaTitle} ${styles.cardBodyAgentTitleMobile}`}
                    text={'Health Agent'}
                />
                {agentDetails?.agentFirstName && (
                    <div className={styles.agentBox}>
                        <div className={styles.agentContactIconsContainer}>
                            <a
                                className={styles.link}
                                href={`mailto:${agentDetails.email}`}
                                rel="noreferrer"
                            >
                                <Icon
                                    image={envelopeIcon}
                                    className={styles.agentContactIcon}
                                />
                            </a>
                            <a
                                className={styles.link}
                                href={`tel:${phoneNumber}`}
                                rel="noreferrer"
                            >
                                <Icon
                                    image={phoneIcon}
                                    className={styles.agentContactIcon}
                                />
                            </a>
                            <Icon
                                image={
                                    agentDetails.isPurlToggledOn
                                        ? verifiedIcon
                                        : notAvailableIcon
                                }
                                className={`${styles.agentContactIcon} ${styles.agentVerifiedIcon}`}
                            />
                        </div>
                        <div className={styles.agentDetailsPicNameContainer}>
                            {agentDetails.profilePicture ? (
                                <Avatar
                                    src={avatarIcon}
                                    alt="agent"
                                    className={styles.avatarIcon}
                                />
                            ) : (
                                <ProfilePicture
                                    className={styles.avatarIcon}
                                    hideCameraOverlay={true}
                                    firstName={agentDetails?.agentFirstName}
                                    lastName={agentDetails?.agentLastName}
                                />
                            )}
                            <div className={styles.agentNameLinkContainer}>
                                <Heading5
                                    className={styles.agentNameHeading}
                                    text={
                                        agentDetails.agentFirstName +
                                        ' ' +
                                        agentDetails.agentLastName
                                    }
                                />
                                <LinkText
                                    url="#"
                                    onClick={onClickViewAgent}
                                    text={
                                        <span
                                            className={`${styles.viewAgentLink} ${styles.viewAgentFullLink}`}
                                        >
                                            View Agent
                                            <Icon
                                                className={
                                                    styles.arrowClassName
                                                }
                                                image={arrowIcon}
                                            />
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!agentDetails?.agentFirstName && (
                    <div
                        className={`${styles.agentBox} ${styles.agentBoxEmptyState}`}
                    >
                        <div className={styles.connectAgentContainer}>
                            <Heading3
                                text="Get personalised guidance"
                                className={styles.getGuidanceTitle}
                            />

                            <Heading3
                                text={'Connect with an insurance agent'}
                                className={styles.connectAgentText}
                            />
                        </div>
                        <LinkText
                            url={REQUEST_CALL_BACK}
                            text={
                                <span
                                    className={`${styles.viewAgentLink} ${styles.viewAgentLinkEmpty}`}
                                >
                                    Find an Agent
                                    <Icon
                                        className={styles.arrowClassName}
                                        image={arrowIcon}
                                    />
                                </span>
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const InsuranceDetailsInfoCards = () => {
    const dispatch = useDispatch();
    const isGuidedModal = useSelector(
        state => state.guidedDetails.isGuidedModal
    );
    const medicareAgent = useSelector(
        state => state.planTypeDetail.medicareAgent
    ) || {
        agentFirstName: '',
        agentLastName: ''
    };
    const finalExpenseAgent = useSelector(
        state => state.planTypeDetail.finalExpenseAgent
    ) || {
        agentFirstName: '',
        agentLastName: ''
    };
    const [healthPlans, setHealthPlans] = useState([]);
    const [lifePlans, setLifePlans] = useState([]);
    const [insuranceIcon, setInsuranceIcon] = useState();
    const [healthPlanInfo, setHealthPlanInfo] = useState();
    const [lifePlanInfo, setLifePlanInfo] = useState();
    const [isAgentDetailsModalOpen, setIsAgentDetailsModalOpen] =
        useState(false);
    const [isLifeAgentModalOpen, setIsLifeAgentModalOpen] = useState(false);
    const [selectedAgentDetails, setSeletedAgentDetails] = useState();

    const getPlansList = useCallback(async () => {
        try {
            const plans = await getPlans();
            const filterPlans = plans?.filter(
                data => data?.policyStatus === 'Active'
            );
            const lifeFilterPlans = filterPlans?.filter(
                data => data?.productCategory === 'Final Expense'
            );
            const healthFilterPlans = filterPlans?.filter(
                data => data?.productCategory !== 'Final Expense'
            );
            setLifePlans(lifeFilterPlans);
            setHealthPlans(healthFilterPlans);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        getPlansList();
    }, [getPlansList]);

    const openLifePlanModal = (icon, index) => {
        if (lifePlans[0] && lifePlans[0]?.policyStatus) {
            setInsuranceIcon(icon);
            dispatch(setLifePlanContentModal(true));
            setLifePlanInfo(lifePlans[index]);
        }
    };

    const onOpenLifeInsuranceAgentDetailsModal = event => {
        event.preventDefault();
        setIsLifeAgentModalOpen(true);
        setSeletedAgentDetails(finalExpenseAgent);
        setIsAgentDetailsModalOpen(true);
    };

    const onOpenHealthInsuranceAgentDetailsModal = event => {
        event.preventDefault();
        setIsLifeAgentModalOpen(false);
        setSeletedAgentDetails(medicareAgent);
        setIsAgentDetailsModalOpen(true);
    };

    const openHealthPlanModal = (icon, index) => {
        if (healthPlans[0] && healthPlans[0]?.policyStatus) {
            setInsuranceIcon(icon);
            dispatch(setHealthPlanContentModal(true));
            setHealthPlanInfo(healthPlans[index]);
        }
    };

    return (
        <>
            <div className={styles.insuranceDetailsContainer}>
                <InfoCard
                    bodyElement={
                        <LifeInsuranceContent
                            openModal={openLifePlanModal}
                            lifePlans={lifePlans}
                            agentDetails={finalExpenseAgent}
                            onClickViewAgent={
                                onOpenLifeInsuranceAgentDetailsModal
                            }
                        />
                    }
                    heading={lifeInsuranceHeading}
                    containerClassName={styles.lifeInsuranceCard}
                    icon={lifeInsuranceIcon}
                />
                <InfoCard
                    bodyElement={
                        <HealthInsuranceContent
                            openModal={openHealthPlanModal}
                            healthPlans={healthPlans}
                            agentDetails={medicareAgent}
                            onClickViewAgent={
                                onOpenHealthInsuranceAgentDetailsModal
                            }
                        />
                    }
                    heading={heathInsuranceHeading}
                    containerClassName={styles.healthInsuranceCard}
                    icon={healthInsuranceIcon}
                />
            </div>
            <YourLifePlanModal
                insuranceImage={insuranceIcon}
                plansDetails={lifePlanInfo}
            />
            <YourHealthPlanModal
                insuranceImage={insuranceIcon}
                plansDetails={healthPlanInfo}
            />
            <InsuranceAgentDetailsModal
                isLifeAgent={isLifeAgentModalOpen}
                isOpen={isAgentDetailsModalOpen}
                agentDetails={selectedAgentDetails}
                onClose={() => setIsAgentDetailsModalOpen(false)}
            />
            <ConsumerGuidedExperienceModal
                isOpen={isGuidedModal}
                isFromProfile={true}
            />
        </>
    );
};

export default InsuranceDetailsInfoCards;
