import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { setSelectedItem } from 'components/PlanTypeModal/slice';
import { usePurlBanner } from 'components/PurlBanner/usePurlBanner';
import {
    setSelectedQuickProfileItem,
    setTargetPage
} from 'components/QuickProfileHealthModal/slice';

import purlAgentContext from 'contexts/purlAgentContext';

import handleEvent from 'utilities/handleEvent';
import {
    CONTACT_AN_AGENT_ROUTE,
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    REQUEST_CALL_BACK
} from 'utilities/routes';
import { CONTACT_CALLBACK_KEY } from 'utilities/storageKeys';

import styles from './styles.module.scss';

import { set } from 'temp/utilities/storage';

const FooterListItem = ({
    className = '',
    externalURL = false,
    lines1Text = '',
    lines2Text = '',
    link,
    onClick,
    text,
    twoLines = false
}) => {
    const { isAuthenticated } = useAuth0();
    const history = useHistory();
    const handleClick = onClick => handleEvent(onClick);
    const renderTwoLines = useMemo(() => {
        return (
            <>
                <a
                    className={`${styles.link} ${styles.linkBlock}`}
                    href={link}
                    rel="noreferrer"
                    target="_blank"
                >
                    {lines1Text}
                </a>
                <a
                    className={`${styles.link} ${styles.linkBlock}`}
                    href={link}
                    rel="noreferrer"
                    target="_blank"
                >
                    {lines2Text}
                </a>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderExternalURLComponent = () => {
        if (twoLines) {
            return renderTwoLines;
        }

        return (
            <a className={styles.link} href={link} rel="noreferrer">
                {text}
            </a>
        );
    };
    const dispatch = useDispatch();
    const { HasMedicareAgentData, HasFinalExpenseAgentData } = usePurlBanner();
    const data = {
        medicareLink: REQUEST_CALL_BACK,
        finalExpenseLink: FINAL_EXPENSE_REQUEST_CALL_BACK,
        disableMedicarePlanType: HasMedicareAgentData(),
        disableFinalExpensePlanType: HasFinalExpenseAgentData()
    };
    const currentPage = window.location.href.substring(
        window.location.href.indexOf('#') + 1
    );

    return (
        <li
            className={`${styles.footerListItem} ${className}`}
            id={typeof text === 'string' ? text?.replaceAll(' ', '_') : ''}
        >
            {link ? (
                externalURL ? (
                    renderExternalURLComponent()
                ) : (
                    <Link
                        className={styles.link}
                        data-click-type="link"
                        onClick={e => {
                            e.preventDefault();
                            handleClick(e);
                            if (
                                window.location.href.includes(
                                    'quick-profile'
                                ) &&
                                currentPage !== 'personalInformation' &&
                                currentPage !== 'complete' &&
                                window.location.hash &&
                                !isAuthenticated
                            ) {
                                dispatch(setTargetPage('Logo'));
                                dispatch(setSelectedQuickProfileItem(link));
                            } else if (link === CONTACT_AN_AGENT_ROUTE) {
                                set(CONTACT_CALLBACK_KEY, 'true');
                                dispatch(setSelectedItem(data));
                            } else history.push(link);
                        }}
                        id={
                            typeof text === 'string'
                                ? `${text?.replaceAll(' ', '_')}Link`
                                : ''
                        }
                    >
                        {text}
                    </Link>
                )
            ) : (
                text
            )}
        </li>
    );
};

export default FooterListItem;
