import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { AgentAvailableComponentPurl } from 'components/PurlBannerAgentInfo';
import Text from 'components/Text';

import { formatPhoneNumberWithBrackets } from 'utilities/formatPhoneNumber';
import { QUICK_PROFILE_ROUTE } from 'utilities/routes';

import envelope from './images/envelope.svg';
import phone from './images/phone.svg';

import styles from './styles.module.scss';

export const PurlBannerContactInfo = ({ purlAgentData, isMobile }) => {
    const history = useHistory();
    const {
        agentProfessionalInfoResponse,
        isMedicareAgent,
        isPurlToggledOn,
        virtualNumber
    } = purlAgentData || {};
    const { agentOfficeLocation: office, formattedHours = '' } =
        agentProfessionalInfoResponse || {};
    const { city = '', state = '' } = office || {};
    const availableHours = formattedHours?.split(',').filter(Boolean) || [];
    let phoneNumber = virtualNumber?.substring(2);

    const npnCAContent = (
        <div className={styles.npnCIAContainer}>
            <Text className={styles.npnText}>
                NPN: {purlAgentData.agentNpn}
            </Text>
            {purlAgentData.caLicense && (
                <Text
                    className={purlAgentData.agentNpn ? styles.ciaLicense : ''}
                >
                    California License#: {purlAgentData.caLicense}
                </Text>
            )}
        </div>
    );

    return (
        <div className={styles.purlAgentContactContainer}>
            {isMobile && (
                <>
                    {npnCAContent}
                    <Text className={styles.purlAgentSubText}>
                        {(city || state) && (
                            <>
                                <Text>
                                    {[city, state].filter(Boolean).join(', ')}
                                </Text>
                            </>
                        )}
                        <div
                            className={`${
                                city || state ? '' : styles.noBorder
                            } ${styles.availableHourContainer}`}
                        >
                            {availableHours.map(hour => (
                                <Text>{hour}</Text>
                            ))}
                        </div>
                    </Text>
                </>
            )}
            {!isMobile && (
                <AgentAvailableComponentPurl
                    isPurlToggledOn={isPurlToggledOn}
                />
            )}
            <a
                className={styles.link}
                href={`mailto:${purlAgentData.email}`}
                rel="noreferrer"
            >
                <div className={styles.contactInfoItem}>
                    <div>{purlAgentData.email} </div>
                    <div>
                        <Icon
                            className={`${styles.envelopeIcon}`}
                            image={envelope}
                        />
                    </div>
                </div>
            </a>
            <a
                className={styles.link}
                href={`tel:${phoneNumber}`}
                rel="noreferrer"
            >
                <div className={styles.contactInfoItem}>
                    <div>{formatPhoneNumberWithBrackets(phoneNumber)} </div>
                    <div>
                        <Icon className={`${styles.phoneIcon}`} image={phone} />
                    </div>
                </div>
            </a>
            {window.location.pathname !== QUICK_PROFILE_ROUTE &&
                isMedicareAgent && (
                    <div className={styles.getSyncContainer}>
                        <Button
                            label="Get Synced"
                            className={styles.getSyncBtn}
                            onClick={() => history.push(QUICK_PROFILE_ROUTE)}
                        />
                    </div>
                )}
        </div>
    );
};
